import { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Ls } from "../../utility/utility";
import { PatientpageContext } from "../../context/GlobalContext";
import DoctorsAppointmentListItemNew from "../../components/DoctorsAppointmentListItemNew";
import PrescriptionEditor from "../../components/PrescriptionEditor";
import { FormHelperText } from "@material-ui/core";
import Button from "../../Tools/Button";
import Drug_medi from "./Drug_medi";
import PrescriptionTemplate from "./PrescriptionTemplate";
import PatientNotes from "./PatientNotes";
import Treatment_Procedure from "./Treatment_Procedure";
import CircularProgress from "@mui/material/CircularProgress";
import Refered from "./Refered";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import PrescriptionHistoryTable from "./PrescriptionHistoryTable ";
const PrescriptionNotes = ({ id, buttonData, presdata }) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#199A8E",
      fontSize: 13,
      color: theme.palette.common.white,
      padding: "12px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "10px",
      textAlign: "start",
      borderBottom: "1px solid rgba(224, 224, 224, 1)", // Add border bottom to cells
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:not(:last-child)": {
      borderBottom: "1px solid rgba(224, 224, 224, 1)", // Add border bottom to rows except last one
    },
    // Keep the existing style
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const defaultReferalValues = {
    salutation: "Dr.",
    name: "",
    speciality: "",
    mobile: "",
    email: "",
    address: "",
  };
  const history = useHistory();
  const params = useParams();
  const doctorContext = useContext(PatientpageContext);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [formData, setFormData] = useState(defaultReferalValues);
  const [displayName, setDisplayName] = useState("");
  const [mappedData, setMappedData] = useState({});
  const [fetchedFromAPI, setFetchedFromAPI] = useState(false);
  const [historyData, setHistoryData] = useState([]);
 
  const prescriptionInnerData = {
    patient_history: {
      fetchType: "static_list",
      placeholder: "Input Patient History",
    },
    reported_problems: {
      fetchType: "symptoms_list",
      placeholder: "Input Reported Problems/diagnosis",
      hasError: true,
    },
    provisional_diagnosis: {
      fetchType: "symptoms_list",
      placeholder: "Input Reported Provisional Diagnosis",
      hasError: true,
    },
    diagnosis_tests: {
      fetchType: "tests_list",
      placeholder: "Input Recommended Tests",
    },
    investigation_reports: {
      fetchType: "findings_list",
      placeholder: "Input Your Findings",
      hasError: true,
    },
    final_diagnosis: {
      fetchType: "static_list",
      placeholder: "Input final diagnosis",
    },
    advice: {
      fetchType: "static_list",
      placeholder: "Input advice",
    },
    additional_notes: {
      fetchType: "static_list",
      placeholder: "Input additional note",
    },
  };

  const idToDisplayNameMapping = {
    patient_history: "Patient History",
    reported_problems: "Chief Complaints",
    provisional_diagnosis: "Provisional Diagnosis",
    diagnosis_tests: "Diagnosis Test",
    investigation_reports: "Investigation Report Findings",
    final_diagnosis: "Final Diagnosis",
    advice: "Advice",
    additional_notes: "Additional Notes",
    treatments_procedures: "Treatment/Procedure/Schedule",
    medications_drugs: "Drugs/Medication",
    referred_to: "Referred To",
  };

  const renderListItems = (items) => {
    if (!items || items.length === 0) return "";
    return items.map((item, index) => (
      <div key={index} className="mb-1">
        {item}
      </div>
    ));
  };

  const getDisplayData = (row, displayName) => {
    const dataMapping = {
      "Patient History": row.patient_history,
      "Chief Complaints": row.reported_problems,
      "Provisional Diagnosis": row.provisional_diagnosis,
      "Diagnosis Test": row.diagnosis_tests,
      "Investigation Report Findings": row.investigation_reports,
      "Final Diagnosis": row.final_diagnosis,
      "Advice": row.advice,
      "Additional Notes": row.additional_notes
    };

    return renderListItems(dataMapping[displayName]);
  };

  const [receiveNotes, setReceiveNotes] = useState({
    symptoms_list: [],
    findings_list: [],
    tests_list: [],
  });

  const pdataObj = {
    symptoms_list: "symptoms",
    findings_list: "diseases",
    tests_list: "diagnosis",
    drugs_list: "therapies",
  };

  const DefaultpData = {
    symptoms_list: [],
    findings_list: [],
    tests_list: [],
    static_list: [],
    diagnosis_tests: [],
  };

  const [pData, setPData] = useState(DefaultpData);

  const defaultMedicationError = {
    medication: {
      hasError: false,
      errorMsg: "",
    },
    medication_form: {
      hasError: false,
      errorMsg: "",
    },
    dose_qty: {
      hasError: false,
      errorMsg: "",
    },
    dose_unit: {
      hasError: false,
      errorMsg: "",
    },
    dose_admin_method: {
      hasError: false,
      errorMsg: "",
    },
    dose_timings: {
      hasError: false,
      errorMsg: "",
    },
    dietary_instructions: {
      hasError: false,
      errorMsg: "",
    },
    // remind_patient: {
    //   hasError: false,
    //   errorMsg: "",
    // },
    medication_start_date: {
      hasError: false,
      errorMsg: "",
    },
    side_effects_explained: {
      hasError: false,
      errorMsg: "",
    },
    duration: {
      hasError: false,
      errorMsg: "",
    },
    // symptoms_list: {
    //   hasError: false,
    //   errorMsg: "",
    // },
    // findings_list: {
    //   hasError: false,
    //   errorMsg: "",
    // },
  };

  function updatePData(value, type) {
    let addedValue = { ...pData, [type]: value };
    setPData(addedValue);
  }

  const [medicationError, setMedicationError] = useState(
    defaultMedicationError
  );

  useEffect(() => {
    if (presdata) {
      const { note, schedule, medication } = presdata.templates;
      const mappedData = {
        symptoms_list: note.patient_symptoms || [],
        findings_list: note.doc_findings || [],
        tests_list: note.doc_recommended_tests || [],
        findings_list: note.investigation_reports || [],
        diagnosis_tests: schedule.map((item) => item.instruction_stmt) || [],
        drugs_list: medication || [],
      };
      setMappedData(mappedData);
    }
  }, [presdata]);

  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));

  const handleSubmit = async (id, newPdata = null) => {
    try {
      if (
        id !== "medications_drugs" &&
        id !== "treatments_procedures" &&
        id !== "referred_to"
      ) {
        const { data, error } = await doctorContext.postPutV2Call(
          "/v2/doctor/manage/appointment/note/",
          appointment?.appointmentItem?.appointment_id + `/${id}`,
          {
            note_list: newPdata
              ? newPdata[prescriptionInnerData[id].fetchType]
              : pData[prescriptionInnerData[id].fetchType],
          },
          setButtonLoader,
          true
        );
        if (data.data) {
          !newPdata &&
            doctorContext?.setState({
              msg: data?.data?.message || "",
              type: "success",
              timer: null,
              vertical: "top",
              horizontal: "center",
            });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          !!id &&
          id !== "medications_drugs" &&
          id !== "treatments_procedures" &&
          id !== "referred_to" &&
          id !== "generate"
        ) {
          const { data, error } = await doctorContext?.getv2Call(
            "/v2/doctor/manage/appointment/note/",
            appointment?.appointmentItem?.appointment_id + `/${id}`
          );
          if (data?.data) {
            // setChief(Chiefcomplaints.data);
            let newData = { ...pData };
            // console.log(data?.data[id]);
            setHistoryData(data?.data[`complete_${id}`]);
            newData[prescriptionInnerData[id].fetchType] = data?.data[id];
            setFetchedFromAPI(true);
            setPData(newData);

            // const completeDataKey = getCompleteDataKey(id);
            // if (data?.data[completeDataKey]) {
            //   setHistoryData(data.data[completeDataKey]);
            // }
          }
        }
      } catch (error) {}
    };

    fetchData();
    setDisplayName(idToDisplayNameMapping[id]);

    return () => {
      setPData((prev) => {
        if (id && !!prev[prescriptionInnerData[id]?.fetchType]?.length) {
          handleSubmit(id, prev);
        }
        return DefaultpData;
      });
    };
  }, [id]);

  const DoctorsAppointmentList = ({ appointmentDate, classes }) => {
    return (
      <>
        <DoctorsAppointmentListItemNew
          index={0}
          tabRoute="prescription"
          key={appointment?.appointmentItem?.appointment_id}
          appointmentItem={
            doctorContext?.patientInfo || appointment?.appointmentItem
          }
          classes={classes}
        />
      </>
    );
  };

  const handlebuttonclick = () => {
    history.push("/prescription");
    setDisplayName(id.display_name);
  };

  const PrescriptionType = (key) => {
    switch (key) {
      case "treatments_procedures":
        return <Treatment_Procedure />;
      case "referred_to":
        return <Refered formData={formData} setFormData={setFormData} />;
      case "medications_drugs":
        return <Drug_medi />;
      case "generate":
        return <PrescriptionTemplate data={buttonData} />;
      case "patient_notes":
        return <PatientNotes />;
      default:
        const receivedNotes = fetchedFromAPI
          ? pData?.[prescriptionInnerData[id]?.fetchType] || []
          : mappedData[prescriptionInnerData[id]?.fetchType] || [];

        return (
          <>
            <div className="prescription_editor">
            <PrescriptionHistoryTable 
  historyData={historyData}
  displayName={displayName}
  getDisplayData={getDisplayData}
  StyledTableCell={StyledTableCell}
  StyledTableRow={StyledTableRow}
/>

              <PrescriptionEditor
                fetchType={prescriptionInnerData[id]?.fetchType}
                editable={true}
                placeholder={prescriptionInnerData[id]?.placeholder}
                changeHandler={updatePData}
                asyncData={true}
                listQuery={
                  prescriptionInnerData[id]?.fetchType !== "static_list"
                }
                classes={appointment?.classes}
                pdataObj={pdataObj}
                receiveNotes={receivedNotes}
              />
              {prescriptionInnerData[id]?.hasError &&
                medicationError?.[prescriptionInnerData[id]?.fetchType]
                  ?.hasError && (
                  <FormHelperText className="validation-error">
                    {
                      medicationError?.[prescriptionInnerData[id]?.fetchType]
                        ?.errorMsg
                    }
                  </FormHelperText>
                )}
            </div>
            <div className="save-btn">
              <Button
                text={
                  buttonLoader ? (
                    <CircularProgress size="1rem" color="inherit" />
                  ) : (
                    "Save"
                  )
                }
                handleClick={() => {
                  handleSubmit(id);
                }}
              />
            </div>
          </>
        );
    }
  };
  return (
    <div className="cheif_complaints">{PrescriptionType(id || params?.id)}</div>
  );
};

export default PrescriptionNotes;
