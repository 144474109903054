import React, { useContext, useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Input,
} from "@mui/material";
import PrescriptionEditorNew from "../../components/PrescriptionEditorNew";
import { PatientpageContext } from "../../context/GlobalContext";
import { OverlayType } from "../../OverlayManager/Context";
import DownArrowIcon from "../../static/images/down_arrow.svg";
import { medication_form } from "../../static/staticData/staticData";
import { useHistory } from "react-router-dom";
import DialogScreen from "../../Tools/DialogScreen";

import {
  Navigation,
  removeEmptyValues,
  datePicker,
  Ls,
} from "../../utility/utility";
import { styled } from "@mui/material/styles";
import { OverlayContext } from "../../OverlayManager/Overlay";
import InputButton from "../../Tools/InputButton";
import Switch from "../../Tools/Swtich";
import CalendarMonth from "../../Tools/CalendarMonth";
import { tableCellClasses } from "@mui/material/TableCell";
import Button from "../../Tools/Button";
import { FormHelperText } from "@material-ui/core";
import dayjs from "dayjs";
import RightArrowIcon from "../../static/images/Arrow--right.svg";
import { ServiceCall } from "../../utility/service-call";
import CircularProgress from "@mui/material/CircularProgress";
import PrescriptionPrompt from "../../components/PrescriptionPrompt";
import EyeIcon from "../../static/images/eyeSvg.svg";
import ShareIcon from "../../static/images/shareSvg.svg";
import SwipableButton from "../../components/SwipableButton";
import { apiCall } from "../../utility/service-call";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import PrescriptionShareIcon from "../../static/images/prescriptionShare.png";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Backdrop from "@mui/material/Backdrop";

const Drugmedi = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#199A8E",
      fontSize: 13,
      color: theme.palette.common.white,
      padding: "12px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "10px",
      textAlign: "start",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const history = useHistory();
  const doctorContext = useContext(PatientpageContext);
  const Overlay = useContext(OverlayContext);
  const setState = doctorContext.setState;
  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));
  const [addMore, setAddMore] = useState(false);
  const [recentRecords, setRecentRecords] = useState([]);
  const [selectedRecords, setToSelectRecords] = useState("");
  const [snackbar, setSnackbar] = useState({ open: false, msg: "" });
  const listMedication = doctorContext?.listMedications;
  const [sharePrescription, setSharePrescription] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.history.pushState(null, null, window.location.herf);
      window.onpopstate = () => {
        window.history.go(1);
        doctorContext?.dialogController({
          open: true,
          content: (() => (
            <div className="appointment_screen_exit">
              Do you really wish to exit?
            </div>
          ))(),
          checkLoginPage: false,
          promptWindow: true,
          dialogAction: (() => (
            <div className="appointment_screenexit_buttons">
              <Button
                handleClick={() => {
                  doctorContext.handleDialogClose();
                  Ls.remove("pt_key");
                  Ls.remove("pt_token");
                  history.push({
                    pathname: "/",
                  });
                }}
                className="mr-2"
                text="Exit"
                containerClass="m-auto appointment_screen_verify"
              ></Button>
              <Button
                handleClick={() => {
                  doctorContext.handleDialogClose();
                }}
                className="mr-2"
                text="CANCEL"
                containerClass="m-auto appointment_screen_verify"
              ></Button>
            </div>
          ))(),
          maxWidth: "sm",
          disableBackdropClick: true,
        });
      };
      if (appointment?.appointmentItem?.appointment_id) {
        doctorContext?.appointmentNotes(
          appointment?.appointmentItem?.appointment_id
        );
        doctorContext.fetchPatientInfo(
          appointment?.appointmentItem?.appointment_id
        );
        doctorContext
          ?.listRecentMedication()
          .then((items) => items && setRecentRecords(items?.records));
        doctorContext?.initailLookups();
      }
    }
    return () => {
      if (typeof window !== "window") {
        window.onpopstate = null;
        doctorContext?.setPrescriptionSymptoms("");
        doctorContext?.setFetchPrescription("");
        doctorContext?.setListMedication &&
          doctorContext?.setListMedication({
            dose_unit: "",
            dose_admin_method: "",
            dietary_instructions: "",
            dose_timings: "",
          });
      }
    };
  }, []);

  useEffect(() => {
    if (
      doctorContext?.prescriptionSymptoms !== "" &&
      doctorContext?.fetchMedication !== ""
    ) {
      setPrescriptionPromptData(doctorContext?.fetchMedication?.medications);
      if (
        typeof doctorContext?.prescriptionSymptoms === "object" &&
        !doctorContext?.prescriptionSymptoms?.message
      ) {
        setReceiveNotes(doctorContext?.prescriptionSymptoms);
        setPData(doctorContext?.prescriptionSymptoms);
      }
    }
  }, [doctorContext?.prescriptionSymptoms, doctorContext?.fetchMedication]);

  const defaultMedicationData = {
    drug_id: "",
    drug_composition: "",
    medication: "",
    medication_form: "Tablet",
    dose_qty: 1,
    dose_unit: "Tablet",
    dose_admin_method: "",
    dose_timings: "od (One time in a day)",
    dietary_instructions: "a.c. (Before Meals)",
    remind_patient: true,
    medication_start_date: dayjs(),
    side_effects_explained: true,
    duration: "",
    other_instructions: "",
    dose_timings_key: "",
  };

  const defaultMedicationError = {
    medication: {
      hasError: false,
      errorMsg: "",
    },
    medication_form: {
      hasError: false,
      errorMsg: "",
    },
    dose_qty: {
      hasError: false,
      errorMsg: "",
    },
    dose_unit: {
      hasError: false,
      errorMsg: "",
    },
    dose_admin_method: {
      hasError: false,
      errorMsg: "",
    },
    dose_timings: {
      hasError: false,
      errorMsg: "",
    },
    dietary_instructions: {
      hasError: false,
      errorMsg: "",
    },
    medication_start_date: {
      hasError: false,
      errorMsg: "",
    },
    side_effects_explained: {
      hasError: false,
      errorMsg: "",
    },
    duration: {
      hasError: false,
      errorMsg: "",
    },
  };

  const Loader = (text) => {
    Overlay.show(OverlayType?.ModalWindow, {
      data: (
        <div className="prescripitionLoader">
          <h3>Please Wait...</h3>
          <p>{text}</p>
          <CircularProgress color="success" />
        </div>
      ),
      removeCloseButton: true,
    });
  };

  const ExtraFeilds = {
    dosage: "",
    duration: "",
    medication: "",
    medication_form: "Tablet",
  };
  const [medicationData, setMedicationData] = useState(defaultMedicationData);
  const [medicationError, setMedicationError] = useState(
    defaultMedicationError
  );
  const [feildsReset, setFeildsReset] = useState(ExtraFeilds);
  const [buttonLoader, setButtonLoader] = useState(false);
  // const [prescription, setPrescription] = useState(true);
  const [PrescriptionPromptData, setPrescriptionPromptData] = useState([]);
  const dateBeforeValidation = (value) => {
    let before = true;
    let today = dayjs();
    if (today.$y <= value.$y && today.$M <= value.$M && today.$D <= value.$D) {
      before = false;
    }
    return before;
  };

  const errorHandling = (value, key) => {
    let errorFeild = medicationError[key];

    switch (key) {
      case "tests_list":
        break;
      case "dose_qty":
      case "duration":
        if (value) {
          errorFeild.hasError = false;
          errorFeild.errorMsg = "";
        } else {
          errorFeild.hasError = true;
          errorFeild.errorMsg = `Please fill ${key} properly`;
        }
        break;
      case "remind_patient":
      case "side_effects_explained":
        if (value) {
          errorFeild.hasError = false;
          errorFeild.errorMsg = "";
        } else {
          errorFeild.hasError = true;
          errorFeild.errorMsg = `Please explain side-effects`;
        }
        break;
      case "medication_start_date":
        if (!dateBeforeValidation(value)) {
          errorFeild.hasError = false;
          errorFeild.errorMsg = "";
        } else {
          errorFeild.hasError = true;
          errorFeild.errorMsg = `Date should not be before ${datePicker(
            "YYYY-MM-DD"
          )}`;
        }
        break;
      case "symptoms_list":
      case "findings_list":
        if (value?.length > 0) {
          errorFeild.hasError = false;
          errorFeild.errorMsg = "";
        } else {
          errorFeild.hasError = true;
          errorFeild.errorMsg = "Please fill above section";
        }
        break;
      default:
        if (value?.toString()?.trim() !== "") {
          errorFeild.hasError = false;
          errorFeild.errorMsg = "";
        } else {
          errorFeild.hasError = true;
          errorFeild.errorMsg = `${key} cannot be empty`;
        }
    }
    setMedicationError({ ...medicationError, [key]: errorFeild });
  };

  const [receiveNotes, setReceiveNotes] = useState({
    symptoms_list: [],
    findings_list: [],
    tests_list: [],
  });

  const pdataObj = {
    symptoms_list: "symptoms",
    findings_list: "diseases",
    tests_list: "diagnosis",
    drugs_list: "therapies",
  };

  const [pData, setPData] = useState({
    symptoms_list: [],
    findings_list: [],
    tests_list: [],
  });

  const handleClick = () => {
    history.push({
      pathname: "/my-appointments",
      search: history?.location?.search,
    });
  };

  function updatePData(value, type) {
    let addedValue = { ...pData, [type]: value };
    setPData(addedValue);
  }

  const handleChange = (e) => {
    let key = e.target.value;
    let value = e.target.checked;
    if (key !== "remind_patient") {
      errorHandling(value, key);
    }
    setMedicationData({
      ...medicationData,
      [key]: value,
    });
  };
  const handleChangeDate = (value, key) => {
    errorHandling(value, key);
    setMedicationData({
      ...medicationData,
      [key]: value,
    });
  };

  const handleChangePrescription = (value, key) => {
    switch (key) {
      case "medication":
        errorHandling(value?.medicine_name, key);

        // Populate additional fields when a medication is selected
        setMedicationData({
          ...medicationData,
          drug_id: value?.drug_id || "",
          drug_composition: value?.drug_composition || "",
          medication: value?.medicine_name,
          medication_form: value?.medication_form || "Tablet", // Default to Tablet if not provided
          dose_qty: value?.dose_qty || 1,
          dose_unit: value?.dose_unit || "Tablet",
          dose_admin_method: value?.dose_admin_method || "",
          dose_timings: value?.dose_timings || "od (One time in a day)",
          dietary_instructions:
            value?.dietary_instructions || "a.c. (Before Meals)",
          duration: value?.duration ? value.duration.toString() : "",
          other_instructions: value?.other_instructions || "",
          dose_timings_key: value?.dose_timings_key || "",
        });
        break;

      case "medication_form":
        errorHandling(value, key);
        setMedicationData({
          ...medicationData,
          dose_unit: value,
          [key]: value,
        });
        break;

      case "duration":
        errorHandling(value, key);
        setMedicationData({
          ...medicationData,
          [key]: value,
        });
        break;

      default:
        setMedicationData({
          ...medicationData,
          ...value,
        });
        setMedicationError({
          ...medicationError,
          ...key,
        });
    }
  };

  const [dosage, setDosage] = useState(feildsReset?.dosage);
  const [duration, setDuration] = useState(feildsReset?.setDuration);

  const [medicationFormList, setMedicationFormList] = useState(medication_form);
  const [prescriptions, setPrescriptions] = useState([
    {
      medication: "",
      medication_form: "",
      drug_composition: "",
      dosage: "",
      duration: "",
    },
  ]);

  useEffect(() => {
    doctorContext
      ?.fetchMedicationType("/medication/types")
      .then(
        (item) => item && item?.records && setMedicationFormList(item?.records)
      );
  }, []);

  const handleCheckDosage = () => {
    if (!!medicationData?.medication && !!medicationData?.medication_form) {
      Overlay.show(OverlayType.PrescriptionDosage, {
        setDosage,
        medicationData,
        handleChangePrescription,
      });
    } else {
      handleChangePrescription(medicationData?.medication, "medication");
      handleChangePrescription(
        medicationData?.medication_form,
        "medication_form"
      );
    }
  };

  

  const handleSubmit = async () => {
    let formErrorbolean = false;
    Object.keys(medicationError).map((item) => {
      errorHandling(medicationData[item], item);
    });
    Object.keys(medicationError).map((item) => {
      if (medicationError[item]?.hasError) {
        formErrorbolean = true;
        setSnackbar({
          ...snackbar,
          open: true,
          msg: `** ${medicationError[item]?.errorMsg}`,
        });
      }
    });

    if (!formErrorbolean) {
      setButtonLoader(true);
      try {

        const updatedMedicationData = removeEmptyValues(medicationData);
        const Medication = await ServiceCall.putv2(
          "v2/doctor/appointment/edit/dental/medic/",
          appointment?.appointmentItem?.appointment_id,
          JSON.stringify({
            ...updatedMedicationData,
            dietary_instructions: listMedication?.dietary_instructions?.filter(
              (item) =>
                item?.term === updatedMedicationData?.dietary_instructions
            )[0]?.definition,
            dose_timings: updatedMedicationData?.dose_timings_key || listMedication?.dose_timings?.filter(
              (item) => item?.term === updatedMedicationData?.dose_timings
            )[0]?.definition,
            dose_admin_method: listMedication?.dose_admin_method?.filter(
              (item) => item?.term === updatedMedicationData?.dose_admin_method
            )[0]?.definition || updatedMedicationData?.dose_admin_method,
            dose_qty: updatedMedicationData?.dose_qty.toString(),
            remind_patient: updatedMedicationData?.remind_patient.toString(),
            medication_start_date:
              updatedMedicationData?.medication_start_date.format("YYYY-MM-DD"),
            side_effects_explained:
              updatedMedicationData?.side_effects_explained.toString(),
          }),
          {
            "Content-Type": "application/json",
            doc_key: Ls.get("docKey"),
            doc_secret: Ls.get("docToken"),
          }
        );

        if ( Medication) {
          setButtonLoader(false);
          doctorContext?.listRecentMedication().then((items) => {
            items &&
              Array.isArray(items?.records) &&
              !!items?.records?.length &&
              setRecentRecords(items?.records);
            // handleRecentMedication(items?.records[0]);
            setToSelectRecords("");
          });
          // setPrescription(false);
          // setAddMore(true);
          if (!selectedRecords) {
            setMedicationData(defaultMedicationData);
            // setPrescription(true);
            setMedicationError(defaultMedicationError);
            setFeildsReset(ExtraFeilds);
          }
          setAddMore((prev) => !prev);
          setDosage("");
          setDuration("");
          // setPrescriptionPromptData([
          //   ...PrescriptionPromptData,
          //   {
          //     data: Medication?.data?.medication_message,
          //     med_id: Medication?.data?.med_id,
          //     reminder: medicationData?.remind_patient,
          //   },
          // ]);
          doctorContext?.medicationFetch(
            appointment?.appointmentItem?.appointment_id
          );
        }
      } catch (err) {
        err &&
          setState({
            msg:
              err?.response?.data?.message ||
              "Network Error please try after some time",
            type: "error",
            timer: null,
            vertical: "top",
            horizontal: "center",
          });
        setButtonLoader(false);
      }
    }
  };

  const handleSharePrescription = async () => {
    Loader("1. Creating Medication Reminder Schedule for Patient");
    let params = history?.location?.search?.replace(
      `&app_id=${appointment?.appointmentItem?.appointment_id}`,
      ""
    );
    try {
      const generateMedication = await ServiceCall.postv2(
        "/v2/doctor/medic/schedule/",
        appointment?.appointmentItem?.appointment_id,
        "",
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (
        generateMedication?.data?.message ===
        "Medications Schedule created successfully."
      ) {
        Loader("2. Sharing the Prescription with Patient");

        const sharePrescription = await ServiceCall.postv2(
          "/v2/doctor/prescription/submission/",
          appointment?.appointmentItem?.appointment_id,
          "",
          {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
          }
        );

        if (sharePrescription) {
          Overlay.show(OverlayType?.ModalWindow, {
            data: (
              <div className="prescripitionLoader">
                <img src={PrescriptionShareIcon} />
                <p>{sharePrescription?.data?.message}</p>
              </div>
            ),
            removeCloseButton: false,
            handleClose: function close() {
              history.push({
                pathname: "/my-appointments",
                search: params,
              });
            },
          });
        }
      } else {
        Overlay.hide();
        setState({
          msg: generateMedication?.data?.message || "",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
    } catch (err) {
      Overlay.hide();
      setState({
        msg: err?.response?.data?.message || "",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const handlePrescriptionDownload = async () => {
    let url = "/v2/doctor/prescriptions/download/";
    Loader("");
    try {
      const PrescriptionGenerate = await ServiceCall.putv2(
        "/v2/doctor/generate/prescription/",
        appointment?.appointmentItem?.appointment_id,
        "",
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );

      if (PrescriptionGenerate) {
        const PrescriptionDownload = await apiCall({
          url,
          method: "get",
          responseType: "blob",
          hosID: appointment?.appointmentItem?.appointment_id,
          headers: {
            doc_secret: Ls.get("docToken"),
            doc_key: Ls.get("docKey"),
            "Content-Type": "application/pdf",
          },
        });
        if (PrescriptionDownload) {
          Overlay.hide();
          let blob = new Blob([PrescriptionDownload.data], {
            type: "application/pdf",
          });
          let url = window.URL.createObjectURL(blob);
          Overlay.show(OverlayType?.ModalWindow, {
            data: (
              <div className="prescription__pdf">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
                  <Viewer fileUrl={url} />
                </Worker>
                <div className="prescription_pdf_container">
                  <a
                    href={url}
                    target="_blank"
                    className="prescription_pdf_button"
                  >
                    <LocalPrintshopIcon />
                  </a>
                  <a
                    download={`prescription-${appointment?.appointmentItem?.appointment_id}`}
                    href={url}
                    className="prescription_pdf_button"
                  >
                    <DownloadForOfflineIcon />
                  </a>
                </div>
              </div>
            ),
            handleClose: () => {},
          });
          setSharePrescription(true);
        }
      }
    } catch (err) {
      Overlay.hide();
      setState({
        msg: err?.response?.data?.message || "",
        type: "error",
        timer: null,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const handleAddMore = () => {
    if (!selectedRecords) {
      setMedicationData(defaultMedicationData);
      // setPrescription(true);
      setMedicationError(defaultMedicationError);
      setFeildsReset(ExtraFeilds);
    }
    setAddMore((prev) => !prev);
    setDosage("");
    setDuration("");
  };

  const handleRecentMedication = (item) => {
    setToSelectRecords(item?.display_name);
    doctorContext?.fetchMedicationDosage(item?.medication).then((list) => {
      setMedicationData({
        ...medicationData,
        ...list?.record,
        dose_admin_method:
          doctorContext.listMedications?.dose_admin_method?.filter((item) =>
            item?.term.includes(list?.record?.dose_admin_method)
          )[0]?.term,
        dietary_instructions:
          doctorContext.listMedications?.dietary_instructions?.filter((item) =>
            item?.term.includes(list?.record?.dietary_instructions)
          )[0]?.term,
        dose_timings: doctorContext.listMedications?.dose_timings?.filter(
          (item) => item?.term.includes(list?.record?.dose_timings)
        )[0]?.term,
      });
      let durationFormate =
        list?.record?.dose_admin_method +
        " - " +
        list?.record?.dose_qty +
        list?.record?.dose_unit +
        " - " +
        list?.record?.dose_timings +
        "....";
      setFeildsReset({
        ...feildsReset,
        dosage: durationFormate,
        duration: list?.record?.duration,
        medication: list?.record?.medication,
        medication_form: list?.record?.medication_form,
      });
    });
  };

  const handleSetPrompt = async (msg) => {
    try {
      const DeletePrescription = await ServiceCall.deletev2(
        "/v2/doctor/appointment/del/dental/medic/",
        `${appointment?.appointmentItem?.appointment_id}/${msg}`,
        {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        }
      );
      if (DeletePrescription) {
        doctorContext?.medicationFetch(
          appointment?.appointmentItem?.appointment_id
        );
        setState({
          msg: DeletePrescription.data.message || "",
          type: "success",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
      }
    } catch (err) {
      setState({
        msg: err?.response?.data?.message || "",
        type: "error",
        timer: 3000,
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  return (
    <>
      <div className="flex flex-col h-full max-h-screen">
        <DialogScreen />
        {doctorContext?.preTemplateLoader && (
          <Backdrop
            sx={{
              color: "#fff",
              opacity: "0.6",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={doctorContext?.preTemplateLoader}
            onClick={() => {}}
          >
            <CircularProgress size="20px" color="inherit" />
          </Backdrop>
        )}
        {PrescriptionPromptData && PrescriptionPromptData.length > 0 ? (
          <div className="flex-none">
            <Table className="w-full min-w-[300px]">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    style={{ textAlign: "center", width: "15%" }}
                  >
                    Medication
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ textAlign: "center", width: "15%" }}
                  >
                    Medication Form
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ textAlign: "center", width: "15%" }}
                  >
                    Dosage
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ textAlign: "center", width: "15%" }}
                  >
                    Duration
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", width: "8%" }}>
                    Reminder
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", width: "8%" }}>
                    Start Date
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", width: "8%" }}>
                    Side Effects Explained?
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ textAlign: "center", width: "5%" }}
                  ></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {PrescriptionPromptData.map((prescription, index) => (
                  <TableRow key={`submitted-${index}`}>
                    <TableCell className="text-center">
                      {prescription.medication}
                    </TableCell>
                    <TableCell className="text-center">
                      {prescription.medication_form}
                    </TableCell>
                    <TableCell className="text-center">
                      {prescription.dosage}
                    </TableCell>
                    <TableCell className="text-center">
                      {prescription.duration}
                    </TableCell>
                    <TableCell className="text-center">
                      {prescription.remind_patient ? "Yes" : "No"}
                    </TableCell>
                    <TableCell className="text-center">
                      {prescription.remind_patient
                        ? prescription.medication_start_date
                        : "-"}
                    </TableCell>
                    <TableCell className="text-center">
                      {prescription.side_effects_explained ? "Yes" : "No"}
                    </TableCell>
                    <TableCell className="text-center">
                      <button
                        onClick={() => handleSetPrompt(prescription?.med_id)}
                        className="text-gray-500 hover:text-gray-700"
                      >
                        <RemoveCircleOutlineIcon className="h-4 w-4" />
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : null}

        <div className="flex-1 overflow-y-auto">
          <Table className="w-full min-w-[300px]" style={{ marginTop: "1rem" }}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{
                    textAlign: "center",
                    width: "15%",
                    backgroundColor: "#e6f2ff",
                    color: "#000000",
                  }}
                >
                  Medication
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    textAlign: "center",
                    width: "15%",
                    backgroundColor: "#e6f2ff",
                    color: "#000000",
                  }}
                >
                  Medication Form
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    textAlign: "center",
                    width: "15%",
                    backgroundColor: "#e6f2ff",
                    color: "#000000",
                  }}
                >
                  Dosage
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    textAlign: "center",
                    width: "15%",
                    backgroundColor: "#e6f2ff",
                    color: "#000000",
                  }}
                >
                  Duration
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    textAlign: "center",
                    width: "8%",
                    backgroundColor: "#e6f2ff",
                    color: "#000000",
                  }}
                >
                  Reminder
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    textAlign: "center",
                    width: "8%",
                    backgroundColor: "#e6f2ff",
                    color: "#000000",
                  }}
                >
                  Start Date
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    textAlign: "center",
                    width: "8%",
                    backgroundColor: "#e6f2ff",
                    color: "#000000",
                  }}
                >
                  Side Effects Explained?
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    textAlign: "center",
                    width: "5%",
                    backgroundColor: "#e6f2ff",
                    color: "#000000",
                  }}
                ></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ textAlign: "center" }}>
                  <PrescriptionEditorNew
                    fetchType="drugs_list"
                    label="medication"
                    editable={true}
                    placeholder="Input Medication"
                    updatePData={updatePData}
                    asyncData={true}
                    pdataObj={pdataObj}
                    icon={DownArrowIcon}
                    feildsReset={feildsReset}
                    changeHandler={handleChangePrescription}
                    receiveNotes={receiveNotes["drugs_list"] || []}
                  />
                  {medicationError?.medication?.hasError && (
                    <FormHelperText className="validation-error">
                      {medicationError?.medication?.errorMsg}
                    </FormHelperText>
                  )}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <PrescriptionEditorNew
                    fetchType="medication/types"
                    label="medication_form"
                    data={medicationFormList}
                    placeholder="Type"
                    updatePData={updatePData}
                    feildsReset={feildsReset}
                    icon={DownArrowIcon}
                    changeHandler={handleChangePrescription}
                  />
                  {(medicationError?.medication?.hasError ||
                    medicationError?.medication_form?.hasError) && (
                    <FormHelperText className="validation-error">
                      {medicationError?.medication?.errorMsg ||
                        medicationError?.medication_form?.errorMsg}
                    </FormHelperText>
                  )}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <div
                    className="pos-relative mb-4"
                    onClick={handleCheckDosage}
                  >
                    <Input
                      placeholder="Dosage"
                      value={
                        (medicationData?.dose_qty &&
                          medicationData?.dose_admin_method) ||
                          ""
                      }
                      style={{ pointerEvents: "none" }}
                      className="w-100 pr-3 font-12"
                    />
                    <div className="autocompLeteLoader pos-absolute">
                      <img src={DownArrowIcon} />
                    </div>
                  </div>
                  {medicationError?.dose_qty?.hasError && (
                    <FormHelperText className="validation-error">
                      Dosage cannot be empty
                    </FormHelperText>
                  )}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <div
                    className="pos-relative mb-4"
                    onClick={() => {
                      Overlay.show(OverlayType.PrescriptionSlider, {
                        setDuration,
                        handleChangePrescription,
                        medicationData,
                      });
                    }}
                  >
                    <Input
                      placeholder="Duration"
                      value={medicationData.duration ? `${medicationData.duration} Days` : ''}
                      style={{ pointerEvents: "none" }}
                      className="w-100 pr-3 font-12"
                    />
                    <div className="autocompLeteLoader pos-absolute">
                      <img src={DownArrowIcon} />
                    </div>
                  </div>
                  {medicationError?.duration?.hasError && (
                    <FormHelperText className="validation-error">
                      {medicationError?.duration?.errorMsg}
                    </FormHelperText>
                  )}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Switch
                    labelInput="remind_patient"
                    checked={medicationData?.remind_patient}
                    handleChange={handleChange}
                  />
                  {medicationError?.remind_patient?.hasError && (
                    <FormHelperText className="validation-error">
                      {medicationError?.remind_patient?.errorMsg}
                    </FormHelperText>
                  )}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {medicationData?.remind_patient ? (
                    <>
                      <CalendarMonth
                        label="Start Date"
                        labelInput="medication_start_date"
                        formate="YYYY-MM-DD"
                        date={medicationData?.medication_start_date}
                        handleChange={handleChangeDate}
                      />
                      {medicationError?.medication_start_date?.hasError && (
                        <FormHelperText className="validation-error">
                          {medicationError?.medication_start_date?.errorMsg}
                        </FormHelperText>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Switch
                    labelInput="side_effects_explained"
                    checked={medicationData?.side_effects_explained}
                    handleChange={handleChange}
                  />
                  {medicationError?.side_effects_explained?.hasError && (
                    <FormHelperText className="validation-error">
                      {medicationError?.side_effects_explained?.errorMsg}
                    </FormHelperText>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Button
            text={buttonLoader ? <CircularProgress size={16} /> : "SAVE"}
            containerClass={`${
              buttonLoader || !medicationData?.side_effects_explained
                ? "prescriptionpage_button_disable"
                : ""
            } prescriptionpage_button_save`}
            handleClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};
export default Drugmedi;
