import { useState, useContext, useEffect, useCallback, useRef } from "react";
import { PatientpageContext } from "../../context/GlobalContext";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CalendarMonth from "../../Tools/CalendarMonth";
import dayjs from "dayjs";
import { constants } from "../../utility/constants";
import { ServiceCall } from "../../utility/service-call";
import { Ls } from "../../utility/utility";
import PageContentLayout from "../../components/PageContentLayout";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import CalendarIcon from "../static/images/Combined-Shape.svg";
import CalendarIcon from "../../static/images/Combined-Shape.svg";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import NotesIcon from "@mui/icons-material/Notes";
import SearchIcon from "../../static/images/svg/SearchIcon";
import { useHistory } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Overlay from "../../components/Overlay";

import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  Select,
  Modal,
  Typography,
  Grid,
  IconButton,
  Box,
  FormHelperText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // Add styles for the FormControl component
    width: "36%",
    marginTop: "18px",
    marginLeft: "42px",
  },
  nameContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      backgroundColor: "#199a8e",
    },
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    flexWrap: "wrap",
    marginTop: theme.spacing(2),
  },
  filterSelect: {
    width: "200px", // Set a fixed or max width
  },
  filterInput: {
    width: "200px", // Set a fixed or max width
  },
  applyButton: {
    height: "54px",
    width: "94px",
  },
}));

const DropdownMenu = ({ anchorEl, handleClose, prescription }) => {
  const history = useHistory();

  const navigateToRoute = (path, prescription) => {
    // const patientId = prescription.pt_profile_id;
    history.push({
      pathname: path,
      // search: `?patientId=${patientId}`,
      state: { prescription },
    });

    handleClose();
  };

  const handleMenuItemClick = (path) => {
    navigateToRoute(path, prescription);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={() => handleMenuItemClick("/Patient-details")}>
        Appointments
      </MenuItem>
      <MenuItem onClick={() => handleMenuItemClick("/my-notes")}>
        Notes
      </MenuItem>
      <MenuItem onClick={() => handleMenuItemClick("/medical-records")}>
        Medical Records
      </MenuItem>
      <MenuItem onClick={() => handleMenuItemClick("/vitals")}>Vitals</MenuItem>
    </Menu>
  );
};

const Mypatient = () => {
  const doctorContext = useContext(PatientpageContext);
  const classes = useStyles();
  const [filterValues, setFilterValues] = useState(() => {
    const savedFilter = localStorage.getItem('filterValues');
    return savedFilter ? JSON.parse(savedFilter) : {
      filter: "",
      filter_value: "",
    };
  });
  const [appointmentLi, setAppointmentList] = useState([]);
  const [searchData, setSearchData] = useState(() => {
    return localStorage.getItem('searchData') || "";
  });
  const [patientdata, setPatientdata] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Initial state is loading
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [pagination, setPagination] = useState({
    page_number: 1,
    page_size: 20,
    hasMore: true,
  });
  
  const [patientDate, setPatientDate] = useState(() => {
    const savedDates = localStorage.getItem('patientDates');
    return savedDates ? JSON.parse(savedDates) : {
      StartDate: dayjs(),
      EndDate: dayjs().add(1, "days"),
    };
  });
  const startDate = doctorContext?.prescriptionDate?.StartDate;
  const patientda = doctorContext?.patientdat;
  const [selectedBubble, setSelectedBubble] = useState(() => {
    return localStorage.getItem('selectedBubble') || 'date';
  });
  const bubbles = [
    { id: "date", label: "Date range" },
    { id: "name", label: "Name" },
    { id: "phone", label: "Phone" },
    { id: "case_id", label: "Case#" },
    { id: "advance_search", label: "Adv.Search" },
  ];

  useEffect(() => {
    localStorage.setItem('selectedBubble', selectedBubble);
    localStorage.setItem('filterValues', JSON.stringify(filterValues));
    localStorage.setItem('searchData', searchData);
    localStorage.setItem('patientDates', JSON.stringify({
      StartDate: patientDate.StartDate,
      EndDate: patientDate.EndDate,
    }));
  }, [selectedBubble, filterValues, searchData, patientDate]);

  useEffect(() => {
    const fetchInitialData = async () => {
      switch (selectedBubble) {
        case 'date':
          await fetchFilteredPatients({
            startDate: dayjs(patientDate.StartDate),
            endDate: dayjs(patientDate.EndDate),
            filterType: 'date'
          });
          break;
          
        case 'advance_search':
          if (filterValues.filter && filterValues.filter_value) {
            await fetchFilteredPatients({
              filterType: 'advance_search',
              filterValue: filterValues.filter_value,
              advanceFilter: filterValues.filter,
            });
          }
          break;
          
        case 'name':
        case 'phone':
        case 'case_id':
          if (searchData) {
            await fetchFilteredPatients({
              filterType: selectedBubble,
              filterValue: searchData,
            });
          }
          break;
          
        default:
          break;
      }
    };

    fetchInitialData();
  }, []); // Run only on mount

  const handlesub = (event, patient) => {
    setAnchorEl(event.currentTarget);
    setSelectedPatient(patient);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchFilteredPatients = async (filterParams = {}, isLoadingMore = false) => {
    setIsLoading(true);
  
    const {
      startDate = patientDate.StartDate,
      endDate = patientDate.EndDate,
      filterType = selectedBubble,
      filterValue = "",
      advanceFilter = "",
      pageNumber = pagination.page_number
    } = filterParams;
  
    try {
      const body = {
        filter: advanceFilter || filterType,
        filter_type: filterType,
        filter_value: filterValue
      };
  
      const headers = {
        doc_secret: Ls.get("docToken"),
        doc_key: Ls.get("docKey")
      };
  
      let url = `v2/doctor/list/facility/patients/pagination/`;
      const hospitalId = constants.hospitalId("hosp_id");
      
      const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
      const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
      
      let urlSuffix;
  
      if (filterType === "date") {
        urlSuffix = `${hospitalId}?start_date=${formattedStartDate}&end_date=${formattedEndDate}&page_number=${pageNumber}&page_size=${pagination.page_size}`;
      } else {
        urlSuffix = `${hospitalId}?page_number=${pageNumber}&page_size=${pagination.page_size}`;
      }
  
      const res = await ServiceCall.postv2(
        url,
        urlSuffix,
        body,
        headers
      );
  
      if (res?.data?.records) {
        // If loading more, append to existing data, otherwise replace it
        setAppointmentList(prevList => 
          isLoadingMore ? [...prevList, ...res.data.records] : res.data.records
        );
        
        // Update hasMore based on whether we received a full page of results
        const isFullPage = res.data.records.length === pagination.page_size;
        setPagination(prev => ({
          ...prev,
          page_number: pageNumber,
          hasMore: isFullPage // Only set hasMore to true if we got a full page
        }));
      } else {
        setPagination(prev => ({
          ...prev,
          hasMore: false
        }));
      }
    } catch (err) {
      console.error("Error fetching patient data:", err);
      setPagination(prev => ({
        ...prev,
        hasMore: false
      }));
    } finally {
      setIsLoading(false);
    }
  };



  
  const handleBubbleSelect = (bubbleId) => {
    setSelectedBubble(bubbleId);
    setSearchData("");
    
    // Clear other filters when switching bubbles
    if (bubbleId !== 'advance_search') {
      setFilterValues({
        filter: "",
        filter_value: "",
      });
    }

    // If selecting date, refresh with date filter
    if (bubbleId === "date") {
      fetchFilteredPatients({
        filterType: bubbleId,
      });
    }
  };

  useEffect(() => {
    if (patientda) {
      setAppointmentList(patientda || []);
    }
  }, [patientda]);

  useEffect(() => {
    const Getmypatient = async () => {
      try {
        const response = await ServiceCall.newget("/facility/pt/data/filters");
        if (response && response.data && response.data.records) {
          setIsLoading(false);
          setPatientdata(response.data.records);
        }
      } catch (err) {}
    };
    Getmypatient();
  }, []);

  const handleFilterChange = (event) => {
    const selectedValue = event.target.value;
    setFilterValues((prevState) => ({
      ...prevState,
      filter: selectedValue,
    }));
  };

  const handleTextFilterChange = (event) => {
    const textValue = event.target.value;
    setFilterValues((prevState) => ({
      ...prevState,
      filter_value: textValue,
    }));
  };

  const handleDateChange = async (value, label) => {
    setPatientDate((prev) => ({
      ...prev,
      [label]: value,
    }));

    await fetchFilteredPatients({
      startDate: label === "StartDate" ? value : patientDate.StartDate,
      endDate: label === "EndDate" ? value : patientDate.EndDate,
    });
  };

  const handleAddService = async () => {
    await fetchFilteredPatients({
      filterType: "advance_search",
      filterValue: filterValues.filter_value,
      advanceFilter: filterValues.filter,
    });
  };

  const handleSearchInput = (e) => {
    setSearchData(e.target.value);
  };

  const handleSearchIconClick = async () => {
    if (searchData.trim()) {
      await fetchFilteredPatients({
        filterType: selectedBubble,
        filterValue: searchData,
      });
    }
  };

  const scrollContainerRef = useRef(null);

  const handleScroll = useCallback(async (e) => {
    const element = e.target;
    const reachedBottom = Math.abs(
      element.scrollHeight - element.scrollTop - element.clientHeight
    ) < 1;

    if (
      reachedBottom && 
      !isLoading && 
      pagination.hasMore // Only true if last response had full page size
    ) {
      const nextPage = pagination.page_number + 1;
      await fetchFilteredPatients(
        {
          pageNumber: nextPage,
          filterType: selectedBubble,
          filterValue: selectedBubble === 'advance_search' ? filterValues.filter_value : searchData,
          advanceFilter: selectedBubble === 'advance_search' ? filterValues.filter : '',
          startDate: patientDate.StartDate,
          endDate: patientDate.EndDate
        },
        true // isLoadingMore = true
      );
    }
  }, [
    pagination, 
    isLoading, 
    selectedBubble, 
    filterValues, 
    searchData, 
    patientDate
  ]);
  
    // Debounce the scroll handler to prevent too many calls
    // const debouncedScroll = useCallback(
    //   (e) => {
    //     if (isLoading) return; // Don't process more scroll events while loading
    //     handleScroll(e);
    //   },
    //   [handleScroll, isLoading]
    // );
  
    // useEffect(() => {
    //   const scrollContainer = scrollContainerRef.current;
    //   if (scrollContainer) {
    //     scrollContainer.addEventListener("scroll", debouncedScroll);
    //     return () =>
    //       scrollContainer.removeEventListener("scroll", debouncedScroll);
    //   }
    // }, [debouncedScroll]);

    
    useEffect(() => {
      const tableContainer = document.querySelector('.appointment__container');
      if (tableContainer) {
        tableContainer.addEventListener('scroll', handleScroll);
        return () => tableContainer.removeEventListener('scroll', handleScroll);
      }
    }, [handleScroll]);
  

  const CalendarMonth = ({ label, formate, handleChange, date }) => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="newappointment__container__calender__datePicker">
        <MobileDatePicker
          label={label}
          inputFormat={formate}
          value={patientDate[label]} // Use patientDate here
          onChange={(value) => handleChange(value, label)}
          renderInput={(params) => <TextField {...params} />}
        />
        <img src={CalendarIcon} alt="Calendar Icon" />
      </div>
    </LocalizationProvider>
  );

  return (
    <>
      {isLoading && (
        <Overlay opacity=".6">
          <CircularProgress size="20px" color="success" />
        </Overlay>
      )}
      <PageContentLayout>
        <div
          className="flex items-center gap-4"
          style={{ display: "flex", margin: "1rem" }}
        >
          <p className="newappointment__container__calender__text">
            Search by:
          </p>
          <div
            className="flex gap-2"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              gap: "1rem",
              marginLeft: "1rem",
            }}
          >
            {bubbles.map((bubble) => (
              <button
                key={bubble.id}
                onClick={() => handleBubbleSelect(bubble.id)}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors`}
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    selectedBubble === bubble.id ? "#199a8e" : "#fff",
                  color: selectedBubble === bubble.id ? "#fff" : "#000",
                }}
              >
                {bubble.label}
              </button>
            ))}
          </div>
        </div>
        <div style={{ margin: "0px" }}>
          {selectedBubble === "date" && (
            <div
              className="prescriptionContainer__heading"
              style={{ marginLeft: "3rem" }}
            >
              Filter the patients by registered between start and end date.
            </div>
          )}
          {selectedBubble === "date" && (
            <div
              className="prescriptionContainer__dates"
              style={{
                marginLeft: "3rem",
                caretColor: "transparent",
                marginBottom: "1rem",
              }}
            >
              <CalendarMonth
                label="StartDate"
                formate="YYYY-MM-DD"
                date={patientDate.StartDate}
                handleChange={handleDateChange}
                style={{
                  "& .MuiInputLabel-root": {
                    marginLeft: "19px",
                  },
                }}
              />
              <CalendarMonth
                label="EndDate"
                formate="YYYY-MM-DD"
                date={patientDate.EndDate}
                handleChange={handleDateChange}
              />
            </div>
          )}
          {selectedBubble === "advance_search" && (
            <>
              <div
                className="prescriptionContainer__heading"
                style={{
                  width: "42%",
                  marginLeft: "3rem",
                  caretColor: "transparent",
                }}
              >
                Filter the patients list using Advance Search option, select
                Adv. Search by drop down, enter text filter and click ‘Apply’
              </div>
              <div
                style={{ margin: "2px", display: "flex", flexDirection: "row" }}
              >
                <FormControl
                  variant="filled"
                  className={classes.formControl}
                  style={{
                    width: "36%",
                    marginTop: "18px",
                    marginLeft: "42px",
                  }}
                >
                  <InputLabel id="salutation-label">Adv. Search</InputLabel>
                  <Select
                    labelId="salutation-label"
                    id="salutation"
                    name="title"
                    value={filterValues.filter}
                    autoWidth
                    onChange={handleFilterChange}
                  >
                    {patientdata &&
                      patientdata.map((item) => (
                        <MenuItem key={item.filter} value={item.filter}>
                          {item.filter_display}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  style={{ width: "32%", margin: "16px" }}
                  value={filterValues.filter_value}
                  onChange={handleTextFilterChange}
                />
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleAddService}
                  style={{ height: "54px", width: "94px" }}
                  disabled={isLoading} // Disable button when loading is true
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Apply"
                  )}
                </Button>
              </div>
            </>
          )}
          {(selectedBubble === "name" ||
            selectedBubble === "phone" ||
            selectedBubble === "case_id") && (
              <div className="prescriptionContainer__search">
              <input
                type="text"
                placeholder={
                  selectedBubble === "name"
                    ? "Search by name"
                    : selectedBubble === "phone"
                    ? "Search by phone"
                    : "Search by case number"
                }
                onChange={handleSearchInput}
                value={searchData}
              />
              <div
                onClick={handleSearchIconClick}
                style={{ cursor: "pointer" }}
              >
                <SearchIcon />
              </div>
            </div>
          )}
        </div>
        <div
        className="py-2 px-3 appointment__container"
        style={{ 
          caretColor: "transparent",
          maxHeight: "500px", // Set a fixed height
          overflowY: "auto"   // Enable vertical scrolling
        }}
      >
          <Table>
            {Array.isArray(appointmentLi) && appointmentLi.length > 0 && (
              <TableHead>
                            {isLoading && pagination.page_number > 1 && (

                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>
                    Patient Name
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>Gender</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Age</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Email</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Phone</TableCell>
                  <TableCell style={{ textAlign: "center" }}></TableCell>
                </TableRow>
                            )}

              </TableHead>
            )}
            <TableBody>
              {Array.isArray(appointmentLi) && appointmentLi.length > 0
                ? appointmentLi.map((prescription, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ textAlign: "center" }}>
                        {prescription.patient_name}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {prescription.patient_gender === "Female"
                          ? "F"
                          : "M" || "NA"}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {prescription.patient_age}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {prescription.email}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {prescription.phone}
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "center", cursor: "pointer" }}
                        onClick={(event) => handlesub(event, prescription)} // Pass the prescription object
                      >
                        <MoreVertIcon />
                      </TableCell>
                      <DropdownMenu
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        prescription={selectedPatient}
                      />
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
          {!isLoading && !pagination.hasMore && appointmentLi.length > 0 && (
            <div style={{ textAlign: "center", padding: "1rem", color: "#666" }}>
              No more records to load
            </div>
          )}
        </div>
      </PageContentLayout>
    </>
  );
};

export default Mypatient;
