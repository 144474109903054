import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const PrescriptionHistoryTable = ({ 
    historyData, 
    displayName, 
    getDisplayData, 
    StyledTableCell, 
    StyledTableRow 
  }) => {


   
    // const renderListItems = (items) => {
    //     if (!items || items.length === 0) return "";
    //     return items.map((item, index) => (
    //       <div key={index} className="mb-1">
    //         {item}
    //       </div>
    //     ));
    //   };
    
    // const getDisplayData = (row, displayName) => {
    //     const dataMapping = {
    //       "Patient History": row.patient_history,
    //       "Chief Complaints": row.reported_problems,
    //       "Provisional Diagnosis": row.provisional_diagnosis,
    //       "Diagnosis Test": row.diagnosis_tests,
    //       "Investigation Report Findings": row.investigation_reports,
    //       "Final Diagnosis": row.final_diagnosis,
    //       "Advice": row.advice,
    //       "Additional Notes": row.additional_notes
    //     };
    
    //     return renderListItems(dataMapping[displayName]);
    //   };
    // If no data, return null to prevent rendering the table
    if (!historyData || historyData.length === 0) {
      return null;
    }
  
    return (
      <TableContainer component={Paper} style={{ marginBottom: ".5rem" }}>
        <Table sx={{ minWidth: 300 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">
                {displayName}
              </StyledTableCell>
              <StyledTableCell align="center">Date</StyledTableCell>
              <StyledTableCell align="center">Doctor</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historyData.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="left" style={{textAlign: "center"}}>
                  {getDisplayData(row, displayName)}
                </StyledTableCell>
                <StyledTableCell align="center" style={{textAlign: "center"}}>
                  {row.appointment_date}
                </StyledTableCell>
                <StyledTableCell align="center" style={{textAlign: "center"}}>
                  {row.doc_name}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
  export default PrescriptionHistoryTable;