import React, { useState, useEffect, useContext } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import ClinicalBubbels from "./ClinicalBubbels";
import Overlay from "./../../components/Overlay";
import { bioMarksBloodPressure } from "./ClinicalUtils";
import CircularProgress from "@mui/material/CircularProgress";
import { PatientpageContext } from "../../context/GlobalContext";
import { Ls } from "../../utility/utility";
import ClinicalSlider from "./ClinicalSlider";
import Backdrop from "@mui/material/Backdrop";
import Button from "../../Tools/Button";

const ClinicalFindings = ({ overlay }) => {
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);
  const doctorContext = useContext(PatientpageContext);
  const [bloodPressure, setBloodPressure] = useState({
    systolic: Number(
      doctorContext?.clinicalsliderData["blood_pressure_syst"]?.data
        ?.latest_measure
    ),
    diastolic:
      Number(doctorContext?.clinicalsliderData["blood_pressure_dias"]?.data
        ?.latest_measure),
    enable: false,
  });
  const appointment =
    doctorContext.appointment || JSON.parse(Ls.get("appointmentData"));

  const clinicalFindingFromStorage = JSON.parse(Ls.get("clinicalFindings"));

  useEffect(() => {
    if (
      clinicalFindingFromStorage &&
      clinicalFindingFromStorage[appointment?.appointmentItem?.appointment_id]
    ) {
      if (!doctorContext?.clinicalFindings.length) {
        let ClinicalFindingsAssign =
          clinicalFindingFromStorage[
            appointment?.appointmentItem?.appointment_id
          ];
        doctorContext?.setClinicalFindings([...ClinicalFindingsAssign]);
      }
    } else {
      doctorContext.ClinicalFindingsCall();
    }

    return () => {
      doctorContext?.setClinicalFindings([]);
    };
  }, []);

  const handleClinicalBubbels = (item, index) => {
    let clinicalFindingsData = [...doctorContext?.clinicalFindings];
    clinicalFindingsData[index] = {
      ...clinicalFindingsData[index],
      checked: item?.checked ? false : true,
    };
    if (
      typeof window !== "undefined" &&
      doctorContext?.fetchPrescription?.prescription_submitted
    ) {
      let newClinicalFindingsToStore = {
        ...clinicalFindingFromStorage,
        [appointment?.appointmentItem?.appointment_id]: clinicalFindingsData,
      };
      Ls.set("clinicalFindings", JSON.stringify(newClinicalFindingsToStore));
    }
    doctorContext?.setClinicalFindings(clinicalFindingsData);
  };

  const handleSubmit = (bio_marker, type) => {
    let url = `${appointment?.appointmentItem?.appointment_id}/${bio_marker}`;
    doctorContext?.updateClinicalFindingCall(
      url,
      {
        measure: bloodPressure[type]?.toString(),
      },
      appointment?.appointmentItem?.appointment_id,
      bio_marker
    );
  };

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="dosage"
    >
      <div className="clinicalFindings">
        {doctorContext?.clinicalLoader && (
          <Backdrop
            sx={{
              color: "#fff",
              opacity: "0.6",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={doctorContext?.clinicalLoader}
            onClick={() => {}}
          >
            <CircularProgress size="20px" color="inherit" />
          </Backdrop>
        )}
        <div
          className="dosage__close"
          onClick={() => {
            handleClose();
            overlay?.context?.handleClose();
            overlay.hide();
          }}
        >
          <CloseIcon />
        </div>
        <div className="clinicalFindings_container">
          <div className="clinicalFindings_heading mt-2 mb-3">
            To capture clinical findings click the below bubbles (Bio-Markers)
          </div>
          <ClinicalBubbels handleOnClick={handleClinicalBubbels} />
          <div className="clinicalFindings_slider mt-4" style={{overflowY: "auto", height: "228px"}}>
            {Array?.isArray(doctorContext?.clinicalFindings) &&
            doctorContext?.clinicalFindings?.length ? (
              doctorContext?.clinicalFindings?.map((items, index) => (
                <React.Fragment key={index}>
                  {items?.checked ? (
                    <div className="mt-2 mb-2">
                      <div className="clinicalFindings_slider_heading">
                        {`${items?.display_name} (${
                          items?.measuring_unit ||
                          items?.diastolic?.measuring_unit
                        })`}
                      </div>
                      <hr />
                      {items?.display_name ===
                      bioMarksBloodPressure?.default ? (
                        <div className="clinicalFindings_slider_bloodPressure mt-4">
                          <ClinicalSlider
                            clinicalFinding={items?.systolic}
                            appointment={appointment}
                            setBloodPressure={setBloodPressure}
                            bloodPressure={bloodPressure}
                          />
                          <ClinicalSlider
                            clinicalFinding={items?.diastolic}
                            appointment={appointment}
                            setBloodPressure={setBloodPressure}
                            bloodPressure={bloodPressure}
                          />
                          <Button
                            loader={!bloodPressure?.enable}
                            handleClick={() => {
                              handleSubmit(
                                items?.systolic?.bio_marker,
                                "systolic"
                              );
                              handleSubmit(
                                items?.diastolic?.bio_marker,
                                "diastolic"
                              );
                            }}
                            containerClass="mt-2 mr-auto ml-auto duration__slider__submit"
                            text="Save"
                          />
                        </div>
                      ) : (
                        <div className="mt-4">
                          <ClinicalSlider
                            clinicalFinding={items}
                            appointment={appointment}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ClinicalFindings;
