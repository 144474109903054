import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TooltipComponent from "./TooltipComponent";
import NotesIcon from "@mui/icons-material/Notes";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const Custompropmt = ({ data, handleSetPrompt }) => {


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#199A8E",
      fontSize: 13,
      color: theme.palette.common.white,
      padding: "12px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "10px",
      textAlign: "start",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const [loader, setLoader] = useState(false);

  if (data && Array.isArray(data) && data.length > 0) {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ textAlign: "center" }}>
                Rx
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                Medication
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                Quantity
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                Dosage
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                Duration
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                  Instructions
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                  Date
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                  Doctor
              </StyledTableCell>
              <StyledTableCell
                style={{ textAlign: "center" }}
              ></StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data &&
              Array.isArray(data) &&
              data.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ textAlign: "center" }}
                  >
                    {row?.medication || ""}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {row?.dose_qty || ""}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {row?.dose_timings || ""}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {row?.duration || ""}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" , display: "flex", alignItems: "center", justifyContent: "center"}}>
                    {/* {row?.notes || ""} */}

                    <div
                      style={{ gap: "0.2rem"}}
                      className="d-flex align-items-center"
                    >
                      {row?.notes ? (
                        <TooltipComponent
                          icon={
                            <NotesIcon
                              sx={{
                                width: "100%",
                                color: "#199a8e",
                                fontSize: "2rem",
                              }}
                            />
                          }
                          title={row?.notes}
                        />
                      ) : (
                        <NotesIcon
                          sx={{
                            width: "100%",
                            color: "#dddddd",
                            fontSize: "2rem",
                          }}
                          disabled
                        />
                      )}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {row?.appoitment_date || ""}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {row?.doc_name || ""}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    <RemoveCircleOutlineIcon
                      onClick={() => {
                        handleSetPrompt(row, index);
                      }}
                      sx={{ fontSize: "15px" }}
                    />{" "}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return <></>;
};

export default Custompropmt;