import { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Ls } from "../../utility/utility";
import { ServiceCall } from "../../utility/service-call";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarIcon from "../../static/images/Combined-Shape.svg";
import dayjs from "dayjs";
import { constants } from "../../utility/constants";
import PageContentLayout from "../../components/PageContentLayout";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  Select,
  Modal,
  Typography,
  Grid,
  IconButton,
  Box,
  FormHelperText,
} from "@material-ui/core";
import { OverlayContext } from "../../OverlayManager/Overlay";
import { OverlayType } from "../../OverlayManager/Context";
import { apiCall } from "../../utility/service-call";
import CircularProgress from "@mui/material/CircularProgress";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { PatientpageContext } from "../../context/GlobalContext";
import CloseIcon from "@material-ui/icons/Close";
import AccountIcon from "../../static/images/AccountIcon.svg";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";


const Medicalrecords = () => {
  const history = useHistory("");
  const [appointmentLoader, setAppointmentLoader] = useState("loading");
  const [overlayLoading, setOverlayLoading] = useState("");
  const Overlay = useContext(OverlayContext);
  const doctorContext = useContext(PatientpageContext);

  const [patientdetailDate, setPatientdetailDate] = useState({
    StartDate: dayjs(),
    EndDate: dayjs().add(1, "days"),
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const patientId = searchParams.get("patientId");
  const prescription = location.state?.prescription;
  const [appointmentLi, setAppointmentList] = useState([]);
  const [showProfilesModal, setShowProfilesModal] = useState(false);
  // const prescription = location.state?.prescription;
  const patientId = prescription?.pt_profile_id;


  const handleChange = (value, label) => {
    let start = label === "StartDate" ? value : patientdetailDate?.StartDate;
    let end = label === "EndDate" ? value : patientdetailDate?.EndDate;

    getAppointmentss(start, end);
    setPatientdetailDate({
      ...patientdetailDate,
      [label]: value,
    });
  };

  const getAppointmentss = function (startDate, endDate) {
    setOverlayLoading("loading");
    setAppointmentLoader("loading");

    ServiceCall.getv2(
      `/v2/doctor/list/patient/records/range/`,
      `${constants.hospitalId("hosp_id")}/${patientId}/${dayjs(
        startDate
      ).format("YYYY-MM-DD")}/${dayjs(endDate).format("YYYY-MM-DD")}`,
      {
        doc_secret: Ls.get("docToken"),
        doc_key: Ls.get("docKey"),
      }
    )
      .then((res) => {
        setAppointmentLoader("completed");
        setOverlayLoading("completed");

        if (res) {
          setAppointmentList(res.data.records);
        }
      })
      .catch((err) => {
        console.log("getAppointmenterrr", err);
        setAppointmentLoader("error");
        setOverlayLoading("error");
      });
  };

  useEffect(() =>{
    getAppointmentss();
  }, [])

  const CalendarMonth = ({ label, formate, handleChange, date }) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="newappointment__container__calender__datePicker">
          <MobileDatePicker
            label={label}
            inputFormat={formate}
            value={date}
            onChange={(value) => handleChange(value, label)}
            renderInput={(params) => <TextField {...params} />}
          />
          <img src={CalendarIcon} />
        </div>
      </LocalizationProvider>
    );
  };

  // hosID: `${constants.hospitalId("hosp_id")}/${patientId}/${file_name}`,
  // const handleDonwnloadPrescription = async (file_name) => {
    // let url = "/v2/doctor/get/patient/record/";


  const handleDonwnloadPrescription = async (file_name) => {
    let url = "/v2/doctor/get/patient/record/";
    try {
      Overlay.show(OverlayType?.ModalWindow, {
        data: (
          <div className="prescripitionLoader">
            <h3>Please Wait...</h3>
            <CircularProgress color="success" />
          </div>
        ),
        removeCloseButton: true,
      });
      const PrescriptionDownload = await apiCall({
        url,
        method: "get",
        responseType: "blob",
        hosID: `${constants.hospitalId("hosp_id")}/${patientId}/${file_name}`,
        headers: {
          doc_secret: Ls.get("docToken"),
          doc_key: Ls.get("docKey"),
        },
      });

      if (PrescriptionDownload) {
        let blob = new Blob([PrescriptionDownload.data], {
          type: "application/pdf",
        });
        let url = window.URL.createObjectURL(blob);
        Overlay.show(OverlayType?.ModalWindow, {
          data: (
            <div className="prescription__pdf">
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={url} />
              </Worker>
              <div className="prescription__pdf__container">
                <a
                  href={url}
                  target="_blank"
                  className="prescription__pdf__button"
                >
                  <LocalPrintshopIcon />
                </a>
                <a
                  download={`prescription-${patientId}`}
                  href={url}
                  className="prescription__pdf__button"
                >
                  <DownloadForOfflineIcon />
                </a>
              </div>
            </div>
          ),
          handleClose: () => {},
        });
        // a.download = appointmentItem?.appointment_id;
        // document.body.append(a);
        // a.click();
        // a.remove();
        // window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      console.log("error", err);
      err &&
        doctorContext.setState({
          msg:
            err?.response?.data?.message ||
            "Network Error please try after some time",
          type: "error",
          timer: null,
          vertical: "top",
          horizontal: "center",
        });
    }
  };

  const handlle = () => {
    history.push("/my-patients");
  };

  const handleopen = () => {
    setShowProfilesModal(true);

    // Trigger reflow for CSS animation
    const element = document.querySelector(".typing-text");
    if (element) {
      element.style.display = "none";
      void element.offsetHeight; // Reflow
      element.style.display = "block";
    }
  };

  return (
    
    <PageContentLayout>
      <div className="medical">
      <div className="pnh-upr" onClick={handlle}>
        <ArrowBackIosIcon style={{cursor: "pointer"}}/>
            <img src={AccountIcon} alt="accountIcon" />
            <p>{prescription.patient_name}</p>
            <span className="pnh-time">
              ({prescription.patient_age}, {prescription.patient_gender})
            </span>
          </div>
        <div className="prescriptionContainer__dates">
          <CalendarMonth
            label="StartDate"
            formate="YYYY-MM-DD"
            date={patientdetailDate?.StartDate}
            handleChange={handleChange}
          />
          <CalendarMonth
            label="EndDate"
            formate="YYYY-MM-DD"
            date={patientdetailDate?.EndDate}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div style={{ marginTop: "2rem" }}>
        {appointmentLi?.map((appointmentLi, index) => (
          <Grid
            key={index + 1}
            className={`py-2 px-3 appointment__container patientRecordsContainer`}
            container
            spacing={2}
            item
            xs={11}
            justifyContent="space-around"
            alignItems="center"
            style={{ cursor: "pointer", width: "72%" }}
          >
            <Grid className="d-flex flex-column align" sm={4} xm={4}>
              <h3 style={{ color: "#199a8e", margin: "0" }}>
                {appointmentLi?.category || ""}
              </h3>
              <span className="mt-1">{`${appointmentLi?.file_name || ""
                // .split("_")[1]
                // ?.slice(0, 10)}.${appointmentLi?.file_name?.split(".")[1] 
                }`}</span>
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              className="appointment__container__middleSection"
            >
              <div className="font-13 px-1 d-flex flex-column w-100 align-items-center">
                <div className="patientRecordsContainer_time">
                  {dayjs(
                    appointmentLi?.uploaded_on?.split(" ")[1],
                    "HH:mm:ss"
                  ).format("hh:mm A")}
                </div>
                <div className="patientRecordsContainer_date mt-1">
                  {dayjs(appointmentLi?.uploaded_on?.split(" ")[0]).format(
                    "D-MMM-YYYY"
                  )}
                </div>
              </div>
            </Grid>
            <Grid
              item
              container
              xs={4}
              sm={4}
              className={`appointment__container__lastsection`}
            >
              <div className="appointment__container__lastsection__container">
                <RemoveRedEyeIcon
                  sx={{ width: "100%", color: "#199a8e", fontSize: "3rem" }}
                  onClick={() => handleDonwnloadPrescription(appointmentLi.file_name)}
                />
              </div>
            </Grid>
            {/* <Grid
              item
              container
              xs={4}
              sm={4}
              className={`appointment__container__lastsection`}
            >
              <div className="appointment__container__lastsection__container" onClick={handleopen}>
                <AutoAwesomeIcon
                  sx={{ width: "100%", color: "#199a8e", fontSize: "3rem" }}
                />
              </div>
            </Grid> */}
          </Grid>
        ))}
      </div>
      <Modal
        open={showProfilesModal}
        onClose={() => setShowProfilesModal(false)}
        aria-labelledby="profiles-modal-title"
        aria-describedby="profiles-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <CloseIcon
            onClick={() => setShowProfilesModal(false)}
            style={{ float: "right", cursor: "pointer" }}
          />
          <div className="typing-container">
            <p className="typing-text">
            The report provides a comprehensive health analysis, summarizing various test results with notable deviations in several parameters:
1.	HsCRP (High Sensitivity C-Reactive Protein): Slightly elevated at 3.46 mg/L, indicating potential inflammation. Recommendations include a healthy diet, regular exercise, and stress management techniques.
2.	Homocysteine: Elevated levels, suggesting a risk for cardiovascular disease. Suggested dietary adjustments include foods rich in B vitamins, physical activity, and avoiding smoking.
3.	HDL Cholesterol: Slightly low at 39.7 mg/dL. Increasing intake of healthy fats and regular exercise is advised.
4.	LDL Cholesterol and LDL/HDL Ratio: Both are higher than the normal range, indicating a need to limit saturated and trans fats, and incorporate healthier fats and regular exercise.
5.	SGOT/SGPT Ratio: Slightly lower than normal, suggesting possible liver dysfunction. Recommendations include limiting alcohol, avoiding toxins, and maintaining a balanced diet and hydration.
Overall, the health score is 92 out of 100. The report includes a detailed summary of deranged parameters, dietary and lifestyle suggestions, and future test recommendations for vitamins, hormones, and cardiovascular health

            </p>
          </div>
        </Box>
      </Modal>
    </PageContentLayout>
  );
};

export default Medicalrecords;
